import "./App.css";
import React from "react";

function Button({ label, url }) {
  // Define a function that opens the URL in a new window
  function handleClick() {
    window.open(url, "_blank");
  }

  return (
    <button
      style={{ padding: "10px", margin: "10px", fontSize: "20px" }}
      // Add the onClick attribute and pass the handleClick function
      onClick={handleClick}
    >
      {label}
    </button>
  );
}

function Title({ text }) {
  return <h1 style={{ textAlign: "center", color: "blue" }}>{text}</h1>;
}

function App() {

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Title text="Auth test page" />
      {/* Pass the URL as a prop to the Button component */}
      {/* Also append the device information as query parameters */}
      <Button
        label="Press this to sign in using Plex"
        url={`https://skyfayauthworkerfive.skyfay.workers.dev/`}
      />
    </div>
  );
}

export default App;
